import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-choose-payement-method',
  templateUrl: './choose-payement-method.component.html',
  styleUrls: ['./choose-payement-method.component.css']
})
export class ChoosePayementMethodComponent implements OnInit {
  @Input()
  public name: string;
  public album: string;
  link: string
  constructor(public activeModal: NgbActiveModal, private router: Router) { }

  ngOnInit() {
    if (this.album === 'Emmanuel') {
      this.link = "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=NQXWQ8Q5RCHFA"
    } else if (this.album === 'Ma raison de vivre') {
      this.link = "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=JZGFGBHJLP3TQ"
    }
  }

  dismissModal(transact: string) {
    if (transact === 'banc') {
      let navigationExtras: NavigationExtras = {
        queryParams: { 'album': this.album }
      };
      this.router.navigate(['/customer-data'], navigationExtras);
    }
    this.activeModal.close();
  }

}
