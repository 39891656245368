import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '../core/message.service';


export class Customer {
  name: string;
  email: string;
  country: string;
  city: string;
  postalCode: string;
  street: string;
  album:string;

  constructor(name: string, email: string, country: string, city: string, postalCode: string, street: string, album:string){
    this.name = name;
    this.email = email;
    this.country = country;
    this.city = city;
    this.postalCode = postalCode;
    this.street = street;
    this.album = album;
  }
}

@Component({
  selector: 'app-customer-data',
  templateUrl: './customer-data.component.html',
  styleUrls: ['./customer-data.component.css']
})
export class CustomerDataComponent implements OnInit {
  album:string;
  count: number = 1;
  constructor(private messageService: MessageService, private activatedRoute: ActivatedRoute, private router: Router) { }
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.album =  params['album'];
    });
  }


  onSubmit(form: NgForm) {
    const name = form.value.name;
    const email = form.value.email;
    const country = form.value.country;
    const city = form.value.city;
    const postalCode = form.value.postalCode;
    const street = form.value.street;
    const customer = new Customer(name, email, country, city, postalCode,street, this.album);
    this.messageService.sendTransactionsDataToCustomer(customer, this.count)
   .subscribe(response => {
      console.log(response + 'transaction data sent');
    },
    err => {
      this.router.navigate(['/albums']);
    });
    form.resetForm();
  }
}
