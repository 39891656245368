import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutMeComponent } from './about-me/about-me.component';
import { AlbumsComponent } from './albums/albums.component';
import { ChurchComponent } from './church/church.component';
import { ConcertComponent } from './concert/concert.component';
import { ContactComponent } from './contact/contact.component';
import { CustomerAccessService } from './core/customer-access.service';
import { CustomerDataComponent } from './customer-data/customer-data.component';
import { GalleryComponent } from './gallery/gallery.component';
import { HomeComponent } from './home/home.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { PageNotAvailableComponent } from './page-not-available/page-not-available.component';
import { PreachingsComponent } from './preachings/preachings.component';
import { PaymentSuccessfullComponent } from './shared/choose-payement-method/payment-successfull/payment-successfull.component';
import { StudioComponent } from './studio/studio.component';

const routes: Routes = [
  {path: 'page-not-available', component: PageNotAvailableComponent},
  {
    path: 'customer-data', component: CustomerDataComponent, 
    canActivate:[CustomerAccessService],
    runGuardsAndResolvers: 'always' 
  },
  {path: 'about-me', component: AboutMeComponent},
  {path: 'church', component: ChurchComponent},
  {path: 'studio', component: StudioComponent},
  {path: 'concert', component: ConcertComponent},
  {path: 'albums', component: AlbumsComponent},
  {path: 'preachings', component: PreachingsComponent},
  {path: 'gallery', component: GalleryComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'impressum', component: ImpressumComponent},
 // {path: 'banc-transaction', component: BancTransactionComponent},
  {path: 'payment-successfull', component: PaymentSuccessfullComponent},
  {path: 'home', redirectTo: ''},
  {path: '**', component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
