import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  openAir2018Images:string[] = [];
  constructor() { }

  ngOnInit() {
    this.openAir2018Images = [
      '../../assets/images/open-air-2018/IMG-20190601-WA0005.jpg',
      '../../assets/images/open-air-2018/IMG-20190601-WA0006.jpg',
      '../../assets/images/open-air-2018/IMG-20190601-WA0007.jpg',
      '../../assets/images/open-air-2018/IMG-20190601-WA0009.jpg',
      '../../assets/images/open-air-2018/IMG-20190601-WA0010.jpg',
      '../../assets/images/open-air-2018/IMG-20190601-WA0011.jpg',
      '../../assets/images/open-air-2018/IMG-20190601-WA0012.jpg',
      '../../assets/images/open-air-2018/IMG-20190601-WA0013.jpg'
    ];
  }

}
