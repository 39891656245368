import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.css']
})
export class SideNavbarComponent implements OnInit {

  constructor(protected translate: TranslateService) {

  }

  ngOnInit() {
  }

  changeLanguage(lang){
    this.translate.setDefaultLang(lang);
  }
}
