import { Component, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactService } from 'src/app/contact/contact.service';

@Component({
  selector: 'app-captcha-modal',
  templateUrl: './captcha-modal.component.html',
  styleUrls: ['./captcha-modal.component.css']
})
export class CaptchaModalComponent implements OnInit {
  name:string;
  answer: number;
  a: number;
  b:number;
  constructor(public activeModal: NgbActiveModal, private contcatService:ContactService) { }

  ngOnInit() {
    this.a = Math.floor(Math.random() * 10) + 1  ;
    this.b = Math.floor(Math.random() * 10) + 1  
    this.answer = this.a*this.b;
  }

  checkResponseAndClose(checkForm:NgForm){
    if(checkForm.value.result === this.answer){
      this.contcatService.reciever.next(true);
    } else {
      this.contcatService.reciever.next(false);
    }
    this.activeModal.close('Close click')
    
  }
}
