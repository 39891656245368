import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-banc-transaction',
  templateUrl: './banc-transaction.component.html',
  styleUrls: ['./banc-transaction.component.css']
})
export class BancTransactionComponent implements OnInit {


  constructor(private router: ActivatedRoute) { }

  ngOnInit() {

  }

}
