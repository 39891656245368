import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'cedric-leumaleu';
  selected = 'home';
  isVerticalMenu = false;
  screenWidth: number;
  menuClosed = true;
  constructor(protected router: Router,protected translate: TranslateService) {
      translate.addLangs(['en','fr']);
      translate.setDefaultLang('en');
    }

  ngOnInit(): void {
    this.router.events.subscribe(
      val => {
        const url = this.router.url;
        this.selected = url.split("/")[1];
      });
    this.getScreenSize();
    this.isVerticalMenu = this.screenWidth <= 600;
  }
  openMenu() {
    this.isVerticalMenu = true;
    this.menuClosed = false;
  }

  closeMenu() {
    this.menuClosed = true;
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;
  }
}
