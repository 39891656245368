import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-buy-button',
  templateUrl: './buy-button.component.html',
  styleUrls: ['./buy-button.component.css']
})
export class BuyButtonComponent implements OnInit {

  @Input()
  public paypalReferenceNumber : string;
  constructor() { }

  ngOnInit() {
  }

}
