import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ChoosePayementMethodComponent } from '../shared/choose-payement-method/choose-payement-method.component';
import { Song } from '../shared/model/song';
import { AlbumsService } from './albums.service';

@Component({
  selector: 'app-albums',
  templateUrl: './albums.component.html',
  styleUrls: ['./albums.component.css']
})
export class AlbumsComponent implements OnInit {

  emmanuel: Song[] = [];
  maRaisonDeVivre: Song[] = [];
  emmanuelStoresLinks:string[] = [];
  maRaisonDeVivreStoresLinks:string[] = [];

  constructor(private albumsService:AlbumsService, private modalService: NgbModal, private translateService:TranslateService) { }

  ngOnInit() {
    this.emmanuelStoresLinks = ['https://music.apple.com/de/album/emmanuel/1552687957','https://open.spotify.com/album/2TSZAl2K5AhrPU6nuhvU0q?si=jQlJYRQAT4-MFS16aUaWLA', 'https://music.amazon.com/albums/B08W57N6LW?marketplaceId=ATVPDKIKX0DER&musicTerritory=US', 'https://deezer.page.link/2gUW1aMJCtZyKbZs8', 'https://tidal.com/browse/album/172868202'];
    this.maRaisonDeVivreStoresLinks = ['https://music.apple.com/de/album/ma-raison-de-vivre/1551127160', 'https://open.spotify.com/album/1lm5zcxQudeZjsY9SBnUU8?si=hCmALCVYTqiZ8cxGmcQhRg', 'https://music.amazon.com/albums/B08V8RV79X?marketplaceId=ATVPDKIKX0DER&musicTerritory=US', 'https://deezer.page.link/DMnggd2HrTM5qjnQ6', 'https://tidal.com/browse/album/171472154'];
    this.emmanuel = [
      { titel: 'L´Eternel est mon berger', link: '../../assets/songs/emmanuel/peaces/L´Eternel est mon berger.mp3', paypalReferenceNumber: 'KPLP6YQ54QFNW'},
      { titel: 'Lèves toi', link: '../../assets/songs/emmanuel/peaces/Lèves toi.mp3', paypalReferenceNumber: 'KPLP6YQ54QFNW'},
      { titel: 'Devant ton trone', link: '../../assets/songs/emmanuel/peaces/Devant ton trone.mp3', paypalReferenceNumber: 'KPLP6YQ54QFNW'},
      { titel: 'Le savez-vous', link: '../../assets/songs/emmanuel/peaces/Le savez vous.mp3', paypalReferenceNumber: 'KPLP6YQ54QFNW'},
      { titel: 'Emmanuel', link: '../../assets/songs/emmanuel/peaces/Emmanuel.mp3' , paypalReferenceNumber: 'KPLP6YQ54QFNW'},
      { titel: 'J´abandonne', link: '../../assets/songs/emmanuel/peaces/J´abandonne.mp3' , paypalReferenceNumber: 'KPLP6YQ54QFNW'},
      { titel: 'Mon témoignage', link: '../../assets/songs/emmanuel/peaces/Mon témoignage.mp3' , paypalReferenceNumber: 'KPLP6YQ54QFNW'},
      { titel: 'There is none like you', link: '../../assets/songs/emmanuel/peaces/There is none like you.mp3' , paypalReferenceNumber: 'KPLP6YQ54QFNW'},
      { titel: 'I will never stop', link: '../../assets/songs/emmanuel/peaces/I will never stop (Dedicate to Akeva Manuella Bobale).mp3', paypalReferenceNumber: 'KPLP6YQ54QFNW'}
    ];
    this.maRaisonDeVivre = [
      { titel: 'Ma raison de vivre', link: '../../assets/songs/ma-raison-de-vivre/peaces/Ma raison de vivre.mp3' , paypalReferenceNumber: 'JZGFGBHJLP3TQ'},
      { titel: 'Je te dis merci', link: '../../assets/songs/ma-raison-de-vivre/peaces/Je te dis merci.mp3' , paypalReferenceNumber: 'VX4NU5U6KH7XA'},
      { titel: 'A faithfull friend', link: '../../assets/songs/ma-raison-de-vivre/peaces/A faithfull friend.mp3' , paypalReferenceNumber: 'CCB2RRKURLU66'},
      { titel: 'Sortez d´ici', link: '../../assets/songs/ma-raison-de-vivre/peaces/Sortez d´ici.mp3' , paypalReferenceNumber: 'NP2RKKE5RWC6U'},
      { titel: 'La lampe sur la table', link: '../../assets/songs/ma-raison-de-vivre/peaces/La lampe sur la table.mp3' , paypalReferenceNumber: 'F49V4N4Q2KAUA'},
      { titel: 'Jésus m´a délivré', link: '../../assets/songs/ma-raison-de-vivre/peaces/Jésus m´a délivré.mp3' , paypalReferenceNumber: 'Z62HLP5P5PYV8'},
      { titel: 'Lord make me clean', link: '../../assets/songs/ma-raison-de-vivre/peaces/Lord make me clean.mp3' , paypalReferenceNumber: 'ETYX3GZXEAXLC'},
      { titel: 'Quand tu viens tout change', link: '../../assets/songs/ma-raison-de-vivre/peaces/Quand tu viens tout change.mp3' , paypalReferenceNumber: 'YYNJSVEW6BRB4'},
      { titel: 'Un homme de bien', link: '../../assets/songs/ma-raison-de-vivre/peaces/Un homme de bien.mp3' , paypalReferenceNumber: 'KPLP6YQ54QFNW'},
      { titel: 'Un nouveau chapitre', link: '../../assets/songs/ma-raison-de-vivre/peaces/Un nouveau chapitre.mp3' , paypalReferenceNumber: 'HVT4ZRVNJ3X5C'}
    ];
  }

  downloadSong(){
    this.albumsService.downloadSong();
  }

  open(album:string) {
    const modalRef = this.modalService.open(ChoosePayementMethodComponent);
    modalRef.componentInstance.name = this.translateService.instant('albums.choosePaymentMethod'); 
    modalRef.componentInstance.album = album;
  }
}