import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-music-stores',
  templateUrl: './music-stores.component.html',
  styleUrls: ['./music-stores.component.css']
})
export class MusicStoresComponent implements OnInit {

  storesIconNames:string[] = ['spotify.png', 'apple.png', 'amazon.png', 'deezer.png', 'itunes.png', 'tidal.png'];
  @Input()
  storeLinks:string[]

  constructor() { }

  ngOnInit() {
  }

  findLinkByStoreIconName(iconName: string){
    const iname = iconName.substring(0, iconName.indexOf('.'));
    for(let link of this.storeLinks){
      if(link.indexOf(iname) > 0){
        return link;
      }
    }
    return undefined;
  }
}
