import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbAlertModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { environment } from 'src/environments/environment.prod';
import { AboutMeComponent } from './about-me/about-me.component';
import { AlbumsComponent } from './albums/albums.component';
import { AlbumsService } from './albums/albums.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BuyButtonComponent } from './buy-button/buy-button.component';
import { ContactComponent } from './contact/contact.component';
import { ContactService } from './contact/contact.service';
import { MessageService } from './core/message.service';
import { GalleryComponent } from './gallery/gallery.component';
import { HomeComponent } from './home/home.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { SafePipe } from './pipe/safe.pipe';
import { PreachingsComponent } from './preachings/preachings.component';
import { AlertMessageComponent } from './shared/alert/alert-message/alert-message.component';
import { CaptchaModalComponent } from './shared/captcha-modal/captcha-modal.component';
import { SideNavbarComponent } from './side-navbar/side-navbar.component';
import { MusicStoresComponent } from './albums/music-stores/music-stores.component';
import { ChoosePayementMethodComponent } from './shared/choose-payement-method/choose-payement-method.component';
import { PaymentSuccessfullComponent } from './shared/choose-payement-method/payment-successfull/payment-successfull.component';
import { BancTransactionComponent } from './shared/choose-payement-method/banc-transaction/banc-transaction.component';
import { PageNotAvailableComponent } from './page-not-available/page-not-available.component';
import { ConcertComponent } from './concert/concert.component';
import { ChurchComponent } from './church/church.component';
import { StudioComponent } from './studio/studio.component';
import { CustomerDataComponent } from './customer-data/customer-data.component';


@NgModule({
  declarations: [
    AppComponent,
    AboutMeComponent,
    AlbumsComponent,
    PreachingsComponent,
    GalleryComponent,
    ContactComponent,
    ImpressumComponent,
    CaptchaModalComponent,
    HomeComponent,
    SafePipe,
    BuyButtonComponent,
    AlertMessageComponent,
    SideNavbarComponent,
    MusicStoresComponent,
    ChoosePayementMethodComponent,
    PaymentSuccessfullComponent,
    BancTransactionComponent,
    PageNotAvailableComponent,
    ConcertComponent,
    ChurchComponent,
    StudioComponent,
    CustomerDataComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    NgbAlertModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  entryComponents: [
    CaptchaModalComponent,
    AlertMessageComponent,
    BuyButtonComponent,
    ChoosePayementMethodComponent
  ],
  providers: [AlbumsService, MessageService, ContactService],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}