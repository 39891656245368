import { Injectable } from '@angular/core';
import{HttpClient} from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class AlbumsService {

  constructor(private http:HttpClient) { 

  }

  downloadSong(){
    this.http.get('https://console.firebase.google.com/project/changemanager-app/storage/changemanager-app.appspot.com/files/actors/moi.png')
    .subscribe(ok =>{});
  }
}
