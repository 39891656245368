import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  response: boolean;
  reciever = new Subject<any>();

  constructor() { }

  getResponse(): boolean {
    return this.response;
  }

  setResponse(response: boolean) {
    this.response = response;
  }

}
