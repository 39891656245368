import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Customer } from '../customer-data/customer-data.component';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(private http: HttpClient) { }
  
  sendTransactionsDataToCustomer(customer: Customer, count:number) :Observable<any> {
    const queryParams = 'name=' + customer.name + '&email=' + customer.email + '&country=' + customer.country + '&postalCode=' + customer.postalCode + '&city=' + customer.city + '&street=' + customer.street + '&count=' + count + '&album=' + customer.album;
    return this.http.get('https://us-central1-cedric-leumaleu-67f31.cloudfunctions.net/transactionMessage?' + queryParams, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      })
    });
  }

  sendMessage(name, email, messageBody){
    this.http.get('https://us-central1-cedric-leumaleu-67f31.cloudfunctions.net/emailMessage?email=' + email + '&name=' + name + '&message=' + messageBody)
    .subscribe(response => {
      console.log(JSON.stringify(response));
    });
  }
}
