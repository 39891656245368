export const environment = {
  production: true,
  firebase : {
    apiKey: "AIzaSyBjKaMMm6M3c8QEUQD0CdObQxZF_dI6mD0",
    authDomain: "cedric-leumaleu-app.firebaseapp.com",
    projectId: "cedric-leumaleu-app",
    storageBucket: "cedric-leumaleu-app.appspot.com",
    messagingSenderId: "760603702663",
    appId: "1:760603702663:web:150dbebe77fea3a32fcf22",
    measurementId: "G-CXL61PD61Y"  
  }
};
