import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from '../core/message.service';
import { CaptchaModalComponent } from '../shared/captcha-modal/captcha-modal.component';
import { ContactService } from './contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  responseIs: boolean = false;
  alertClass:string;
  alertMessage:string;
  checkDone = false;
  email: string;
  name: string;
  messageBody: string;
  constructor(private contcatService: ContactService,private modalService: NgbModal, private messageService:MessageService) { }

  ngOnInit() {
    this.contcatService.reciever.subscribe(resp => {
      this.responseIs = resp;
      if(!resp){
        this.alertClass = "alert alert-danger";
        this.alertMessage = "you are not autorise to send a mail";
      } else {
        this.alertClass = "alert alert-success";
        this.alertMessage = "your E-Mail has been sent";
        this.messageService.sendMessage(this.name, this.email, this.messageBody);
      }
      this.checkDone = true;
      setTimeout(() => {
        this.responseIs = false;
        this.checkDone = false;
      }, 2000);
    });
    
  }

  sendMessage(messageForm:NgForm){
    if(!messageForm.valid){
      return;
    }
    this.email = messageForm.value.email;
    this.name = messageForm.value.yourName;
    this.messageBody = messageForm.value.yourMessage;
    this.open();
    messageForm.reset();
  }

  open() {
    const modalRef = this.modalService.open(CaptchaModalComponent);
    modalRef.componentInstance.name = 'I am not a robot';
  }


}
