import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';



export interface YoutubeItem{
  messagePreview: string;
  title: string;
  link: string;
}
@Component({
  selector: 'app-preachings',
  templateUrl: './preachings.component.html',
  styleUrls: ['./preachings.component.css']
})

export class PreachingsComponent implements OnInit {

  youtubeItems: YoutubeItem[] = []; 

  constructor(protected sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.youtubeItems = [
      {title: 'Wie man den Regen zurückkommen lässt', link: 'https://www.youtube.com/embed/cYFlehEiDPk', messagePreview: 'La pluie dans la bible tient parfois lieux de la bénédiction divine. Or il y a des fois Dieu retient la pluie dans le ciel comme ce fut le cas dans l´histoire du prophet Eli. Nous allons donc ici présenter 5 moyens pour faire revenir la pluie. En d´autre therme 5 moyens comment faire revenir la benediction de Dieu dans sa vie.'},
      {title: 'Wie man den Regen zurückkommen lässt - 2', link: 'https://www.youtube.com/embed/7OkYgp0b9-c', messagePreview: 'La deuxieme partie du message parlera des 3 derniers moyens comment faire revenir la pluie'},
      {title: 'Ein exzellenteres Opfer', link: 'https://www.youtube.com/embed/gxtpLU6T4Xg', messagePreview: 'De la genèse jusqu´au nouveau testament la bible présente des sacrifices qui ont été agréer par Dieu à l´instar de celui d´Abel, d´Abraham, de Salomon et pour fnir celui du Seigneur Jésus. Nous partirons sur la basede ces sacrifices pour expliquer ce que la parole de Dieu entend par sacrifice excellent'},
      {title: 'Ein tiefes Gebet', link: 'https://www.youtube.com/embed/6lNSgaaXN-o', messagePreview: 'Il arrive parfois qu´on soit surperficielle, déconnecté, partagé dans ses pensées lorsqu´on prie. La question qui se pose donc est celle de savoir comment parvenir a une prierre profonde, et même qu´est ce que c´est qu´une prierre profonde?'},
      {title: 'Verachte deine Schwache Anfänge nicht', link: 'https://www.youtube.com/embed/uKmmQRhckw0', messagePreview: 'Des fois l´on peut croire a cause de la manière avec lesquelles les choses évoluent pour nous que ca ne vaut pas la peine de continuer. Mais par ce message je vais te montrer pourquoi il est très important de tenir ferme et d´aller jusqu´au bout de ton action.'},
      {title: 'Ne soit pas jaloux de Joseph et de sa tunique', link: 'https://www.youtube.com/embed/aobWtv5Qg7U', messagePreview: 'Joseph etait haie par ses frères a cause de provilèges qu´il avait devant eux auprès de son père. Cette jalousie les poussera aa le vendre sans savoir qu´ils etaient entrain de l´envoyé devenir leur chef.'}
    ]; 
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }
}
